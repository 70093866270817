import { LessonSparkSVG } from '@newsela/angelou';
import gql from 'graphql-tag';
import smartquotes from 'smartquotes';

import Content from './content';
import MetadataStandard from './metadataStandard';
import MetadataTag from './metadataTag';
import Stream from './stream';
import Tag from './tag';
import TaxonomyTag from './taxonomyTag';

function filter (query) {
  const quoted = smartquotes(query);

  return {
    or: [
      { title: { alloftext: quoted } },
      { body: { alloftext: quoted } }
    ]
  };
}

function quickFilter (query) {
  const quoted = smartquotes(query);

  return {
    title: { alloftext: quoted },
    rootFn: 'title|alloftext'
  };
}

const openSearch = {
  index: 'lesson_spark',
  fields: ['title^10', 'name^7', 'description^5'],
  sort: [
    {
      updated_at: {
        order: 'desc'
      }
    }
  ],
  // If the user included a query we disable the sort so we don't affect the ranking.
  disableSortOnQuery: true,
  _source: ['id', 'content_type', 'name', 'label', 'status', 'streams', 'thumbnail', 'updated_at', 'events'],
  mapping: {
    contentType: 'content_type',
    updatedAt: 'updated_at',
    createdAt: 'created_at'
  }
};

const LessonSpark = {
  previewFragment: gql`
    fragment previewLessonSpark on LessonSpark {
      htmlBody: body(format: TEXT_HTML)
      estimatedTimeToCompleteInMinutes
    }
  `,
  blueprintFragment: gql`
    fragment blueprintLessonSpark on LessonSpark {
      rawTitle: title(format: TEXT_RAW)
      rawBody: body(format: TEXT_RAW)
    }
  `,
  fullFragment: gql`
    fragment fullLessonSpark on LessonSpark {
      title(format: TEXT_PLAIN)
      rawTitle: title(format: TEXT_RAW)
      estimatedTimeToCompleteInMinutes
      body(format: TEXT_PLAIN)
      rawBody: body(format: TEXT_RAW)
      htmlBody: body(format: TEXT_HTML)
      studentInstructions(format: TEXT_PLAIN)
      rawStudentInstructions: studentInstructions(format: TEXT_RAW)
      htmlStudentInstructions: studentInstructions(format: TEXT_HTML)
    }
  `,
  filter,
  quickFilter,
  openSearch,
  defaults: (id, data) => {
    const contentDefaults = Content.defaults(id, data);

    return {
      client: {
        ...contentDefaults.client,
        __typename: 'LessonSpark',
        contentType: 'LESSON_SPARK',
        name: 'Lesson Spark',
        title: null,
        rawTitle: null,
        estimatedTimeToCompleteInMinutes: null,
        body: null,
        rawBody: null,
        htmlBody: null,
        studentInstructions: null,
        rawStudentInstructions: null,
        htmlStudentInstructions: null,
        taxonomyTags: [],
      },
      server: {
        ...contentDefaults.server,
        contentType: 'LESSON_SPARK'
      }
    };
  },
  isContent: true,
  icon: LessonSparkSVG,
  typename: 'Lesson Spark',
  forms: {
    shared: [{
      input: 'prosemirror',
      name: 'title',
      value: 'rawTitle',
      label: 'Title'
    }, {
      input: 'prosemirror',
      name: 'body',
      value: 'rawBody',
      label: 'Description',
      isMultiline: true,
      formats: ['bold', 'italic', 'headings', 'list', 'orderedList', 'link']
    }, {
      input: 'prosemirror',
      name: 'studentInstructions',
      value: 'rawStudentInstructions',
      label: 'Student Instructions',
      isMultiline: true,
      formats: ['bold', 'italic', 'headings', 'list', 'orderedList', 'link']
    }, {
      input: 'text',
      type: 'int',
      name: 'estimatedTimeToCompleteInMinutes',
      label: 'Estimated Time to Complete (in minutes)'
    },
    Tag.inputs.tags,
    MetadataTag.inputs.metadataTags,
    TaxonomyTag.inputs.taxonomyTags,
    MetadataStandard.inputs.metadataStandards,
    Stream.inputs.subjectProductStreams,
    Stream.inputs.customStreams,
    Content.inputs.notes,
    Content.inputs.id,
    Content.inputs.attachmentsCount
    ]
  }
};

export default LessonSpark;
